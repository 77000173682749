import { FC } from 'react';
import Banner from '../components/banner';
import Telegram from '../assets/svg/telegram.svg';
import PFP from '../assets/logo/logo.png';
import X from '../assets/svg/x.svg';
import Birdeye from '../assets/svg/Birdeye.png';
import Solana from '../assets/svg/Solana.svg';
import Raydium from '../assets/svg/Raydium.png';
import Button from '../components/button';
import Anchor from '../components/anchor';
import styled from '@emotion/styled';
import Grid from '../components/grid';
import Stack from '../components/stack';

const ImageWrapper = styled.div({
  width: 48,
  height: 48,
});

const Image = styled.img({
  width: '100%',
  height: '100%',
});

const Intro: FC = () => {
  return (
    <Banner pfp={PFP} heading='Sheesh Meme' description='An SPL Coin prioritizing Memes, Decentralization &amp; Blockchain Tech.'>
      <Stack direction='VERTICAL' space={'var(--scale-48)'} localStyles={{alignItems: 'center'}}>
        <Grid columns={5} gap="var(--scale-24)">
          <Anchor href='http://t.me/ssheesherc20' target='_blank'>
            <ImageWrapper><Image src={Telegram} alt="Telegram"/></ImageWrapper>
          </Anchor>
          <Anchor href='https://twitter.com/SheeshSPL' target='_blank'>
            <ImageWrapper><Image src={X} alt="X"/></ImageWrapper>
          </Anchor>
          <Anchor href='https://birdeye.so/token/ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB?chain=solana' target='_blank'>
            <ImageWrapper><Image src={Birdeye} alt="Birdeye"/></ImageWrapper>
          </Anchor>
          <Anchor href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=ShEEsu&outputCurrency=ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB&fixed=in' target='_blank'>
            <ImageWrapper><Image src={Raydium} alt="Raydium"/></ImageWrapper>
          </Anchor>
          <Anchor href='https://solscan.io/account/ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB' target='_blank'>
            <ImageWrapper><Image src={Solana} alt="Solana"/></ImageWrapper>
          </Anchor>
        </Grid>
        <Button size='L' variant="PRIMARY" as="a" href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=ShEEsu&outputCurrency=ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB&fixed=in' target="_blank">Swap Now</Button>
      </Stack>
    </Banner>
  );
}


export default Intro;


