import { FC } from 'react';
import styled from '@emotion/styled';
import Stack from '../components/stack';
import PFP from '../assets/logo/logo.png';
import Body from '../components/body';
import Box from '../components/box';

const Image = styled.img({
  width: '100%',
  height: '100%',
});

const Footer: FC = () => {
  return (
    <Stack direction='VERTICAL' localStyles={{padding: 'var(--scale-72) var(--scale-36)', height: '100vh', justifyContent: 'center'}}>

      <Stack 
        direction='VERTICAL'
        space={'var(--scale-24)'} 
        localStyles={{
          maxWidth: 300, 
          textAlign: 'center', 
          margin: 'var(--scale-72) auto var(--scale-72)',
          '@media (min-width: 1120px)' :{
            margin: '112px auto var(--scale-72)',
            maxWidth: 640,
          },
        }}>
        <Box 
          localStyles={{
            background: 'linear-gradient(171deg, rgba(172,172,172,1) 0%, rgba(214,214,214,1) 75%, rgba(184,184,184,1) 100%)',
            border: '4px solid #ccc',
            boxShadow: '0 24px 24px 0 rgb(0 0 0 / 24%)',
            width: 100, 
            height: 100, 
            alignItems: 'center', 
            justifyContent: 'center', 
            borderRadius: '100%',
            margin: '0 auto',
            }}>
          <Image src={PFP} alt="Sheesh Logo" />
        </Box>
        <Body size='M'>Sheesh is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. The coin is completely useless and for entertainment purposes only.</Body>
      </Stack>
    </Stack>
  );
}

export default Footer;