import { FC } from 'react';
import Stack from '../components/stack';
import Heading from '../components/heading';
import Body from '../components/body';
import Grid from '../components/grid';
import Responsive from '../components/responsive';
import Button from '../components/button';

const ContentMobile: FC = () => {
  return (
    <>
      <Stack direction='VERTICAL' space={'var(--scale-36)'} 
        localStyles={{
          maxWidth: '52ch',
          margin: '0 auto',
          marginBottom: 'var(--scale-24)',
          '@media (min-width: 1120px)' :{
            maxWidth: 'auto',
          },
        }}>
        <Heading level='2'>ABOUT</Heading>
        <Body size='M'>$SHEESH on solana will provide a launchpad for memes, art, and other creative content that all gets stored within one continuously expanding NFT collection.</Body>
        <Body size='M'>Any user can access our dapp and upload their content whether it be gif, glb, mp4, jpeg, png or other standards and then name it and give it descriptive traits.</Body>
        <Button as="a" variant='PRIMARY' size='M' href="https://x.com/SheeshSPL/status/1746273042689372314?s=20" target='_blank' localStyles={{maxWidth: 200, alignSelf: 'center'}}>Read whitepaper</Button>
      </Stack>
      <Stack direction='VERTICAL' space={'var(--scale-48)'}>
        <Heading level='2'>TOKENOMICS</Heading>
        <Stack direction="VERTICAL" space={'var(--scale-8)'}>
          <Heading level='5'>Total Supply</Heading>
          <Heading level='3'>$4.2Million</Heading>
        </Stack>
        <Stack direction='HORIZONTAL' space={'var(--scale-8)'}>
          <Stack direction="VERTICAL" space={'var(--scale-8)'}>
            <Heading level='5'>Ticker</Heading>
            <Heading level='3'>$SHEESH</Heading>
          </Stack>
          <Stack direction="VERTICAL" space={'var(--scale-8)'}>
            <Heading level='5'>Tax</Heading>
            <Heading level='3'>0%</Heading>
          </Stack>
        </Stack>
        <Stack direction="VERTICAL" space={'var(--scale-4)'} localStyles={{marginTop: 'var(--scale-24)', marginBottom: 'var(--scale-24)'}}>
          <Heading level='6'>Contract</Heading>
          <Body size='M' localStyles={{wordBreak: 'break-word', maxWidth: 270, margin: '0 auto'}}>ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB</Body>
        </Stack>
        <Stack direction="VERTICAL" space={'var(--scale-4)'}>
          <Heading level='6' localStyles={{marginBottom: 'var(--scale-12)'}}>Distribution</Heading>
          <Body size='M'>Pre-Sale • 39%</Body>
          <Body size='M'>Public • 61%</Body>
        </Stack>
      </Stack>
    </>
  );
}

const ContentDesktop: FC = () => {
  return (
    <>
      <Stack direction='VERTICAL' space={'var(--scale-36)'} 
        localStyles={{
          maxWidth: '52ch',
          alignItems: 'start',
        }}>
        <Heading level='2'>ABOUT</Heading>
        <Body size='M'>$SHEESH on solana will provide a launchpad for memes, art, and other creative content that all gets stored within one continuously expanding NFT collection.</Body>
        <Body size='M'>Any user can access our dapp and upload their content whether it be gif, glb, mp4, jpeg, png or other standards and then name it and give it descriptive traits.</Body>
        <Button as="a" variant='PRIMARY' size='M' href="https://x.com/SheeshSPL/status/1746273042689372314?s=20" target='_blank'>Read whitepaper</Button>
      </Stack>
      <Stack direction='VERTICAL' space={'var(--scale-36)'}>
        <Heading level='1'>TOKENOMICS</Heading>
        <Stack direction="VERTICAL" space={'var(--scale-8)'}>
          <Heading level='5'>Total Supply</Heading>
          <Heading level='2'>4.2Million $SHEESH</Heading>
        </Stack>
        <Stack direction='HORIZONTAL' space={'var(--scale-72)'}>
          <Stack direction="VERTICAL" space={'var(--scale-8)'} localStyles={{width: 'auto'}}>
            <Heading level='5'>Ticker</Heading>
            <Heading level='2'>$SHEESH</Heading>
          </Stack>
          <Stack direction="VERTICAL" space={'var(--scale-8)'} localStyles={{width: 'auto'}}>
            <Heading level='5'>Tax</Heading>
            <Heading level='2'>0%</Heading>
          </Stack>
        </Stack>
        <Stack direction="VERTICAL" space={'var(--scale-4)'} localStyles={{marginTop: 'var(--scale-24)', marginBottom: 'var(--scale-24)'}}>
          <Heading level='6'>Contract</Heading>
          <Body size='L' localStyles={{wordBreak: 'break-word'}}>ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB</Body>
        </Stack>
        <Stack direction="VERTICAL" space={'var(--scale-4)'}>
          <Heading level='6' localStyles={{marginBottom: 'var(--scale-12)'}}>Distribution</Heading>
          <Body size='M'>Pre-Sale • 39%</Body>
          <Body size='M'>Public • 61%</Body>
        </Stack>
      </Stack>
    </>
  );
}


const About: FC = () => {
  return (
    <>
      <Responsive below={1120}>
        <Stack 
          direction='VERTICAL' 
          space={'var(--scale-72)'} 
          localStyles={{
            textAlign: 'center', 
            borderRadius: 24, 
            padding: 'var(--scale-60) var(--scale-48) var(--scale-72)', 
            background: 'rgb(0 0 0 / 60%)', 
            minHeight: '100vh', 
            justifyContent: 'center'
          }}>
            <ContentMobile />
        </Stack>
      </Responsive>

      <Responsive above={1120}>
        <Stack 
          direction='VERTICAL' 
          localStyles={{
            padding: 'var(--scale-60)', 
            background: 'rgb(0 0 0 / 60%)', 
            minHeight: '100vh', 
            justifyContent: 'center',
            '@media (min-width: 1250px)' :{
              padding: 96, 
            },
          }}>
        <Grid columns={2} gap='112px' localStyles={{        
          maxWidth: 1200,
          margin: '0 auto var(--scale-48)',
          gridTemplateColumns: '1fr auto'
        }}>
          <ContentDesktop />
        </Grid>
        </Stack>
      </Responsive>
    </>
  );
  }


export default About;